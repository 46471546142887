import { call, put, delay, takeEvery } from "redux-saga/effects";
import { STATUS_CODE } from "../contants";
import * as conatant_type from "../contants/configvalue";

import { fetchConfigByDevieSuccess } from "../actions/configvalue";
import { fetchListsPost } from "../apis/common";

let url_router_default = "config-value/";

function* handleFecthChannelSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "config-by-device/",
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(fetchConfigByDevieSuccess(data));
  }
}

function* handleConfigValue() {
  yield takeEvery(
    conatant_type.CONFIG_VALUE_FETCH_LIST_BY_DEVICE,
    handleFecthChannelSaga
  );
}

export default handleConfigValue;
