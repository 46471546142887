export const DEVICE_FETCH_LIST = "DEVICE_FETCH_LIST";
export const DEVICE_FETCH_LIST_SUCCESS = "DEVICE_FETCH_LIST_SUCCESS";

export const DEVICE_FETCH_ITEM_BY_ID = "DEVICE_FETCH_ITEM_BY_ID";
export const DEVICE_FETCH_ITEM_BY_ID_SUCCESS = "DEVICE_FETCH_ITEM_BY_ID_SUCCESS";

export const DEVICE_CREATE_UPDATE = "DEVICE_CREATE_UPDATE";
export const DEVICE_CREATE_UPDATE_SUCCESS = "DEVICE_CREATE_UPDATE_SUCCESS";

export const DEVICE_DELETE = "DEVICE_DELETE";
export const DEVICE_DELETE_SUCCESS = "DEVICE_DELETE_SUCCESS";

export const DEVICE_UPDATE = "DEVICE_UPDATE";
export const DEVICE_UPDATE_SUCCESS = "DEVICE_UPDATE_SUCCESS";

export const DEVICE_PAYMENT = "DEVICE_PAYMENT";
export const DEVICE_PAYMENT_SUCCESS = "DEVICE_PAYMENT_SUCCESS";


export const DEVICE_FETCH_LIST_RUN = "DEVICE_FETCH_LIST_RUN";
export const DEVICE_FETCH_LIST_RUN_SUCCESS = "DEVICE_FETCH_LIST_RUN_SUCCESS";


export const DEVICE_FETCH_LIST_HISTORY_RUN = "DEVICE_FETCH_LIST_HISTORY_RUN";
export const DEVICE_FETCH_LIST_HISTORY_RUN_SUCCESS = "DEVICE_FETCH_LIST_HISTORY_RUN_SUCCESS";

export const DEVICE_FETCH_GROUP_BY_TIMESTAMP = "DEVICE_FETCH_GROUP_BY_TIMESTAMP";
export const DEVICE_FETCH_GROUP_BY_TIMESTAMP_SUCCESS = "DEVICE_FETCH_GROUP_BY_TIMESTAMP_SUCCESS";

export const DEVICE_FETCH_LIST_ERROR = "DEVICE_FETCH_LIST_ERROR";
export const DEVICE_FETCH_LIST_ERROR_SUCCESS = "DEVICE_FETCH_LIST_ERROR_SUCCESS";

export const DEVICE_FETCH_LIST_ERROR_BY_CHANNEL = "DEVICE_FETCH_LIST_ERROR_BY_CHANNEL";
export const DEVICE_FETCH_LIST_ERROR_BY_CHANNEL_SUCCESS = "DEVICE_FETCH_LIST_ERROR_BY_CHANNEL_SUCCESS";

export  const FETCH_LIST_DEVICE = 'FETCH_LIST_DEVICE';
export const  FETCH_LIST_DEVICE_SUCCESS = 'FETCH_LIST_DEVICE_SUCCESS';

export  const USER_CONNECT_DEVICE = 'USER_CONNECT_DEVICE';
export  const USER_CONNECT_DEVICE_SUCCESS = 'USER_CONNECT_DEVICE_SUCCESS';
export  const USER_REMOVE_CONNECT_DEVICE = 'USER_REMOVE_CONNECT_DEVICE';
export  const USER_REMOVE_CONNECT_DEVICE_SUCCESS = 'USER_REMOVE_CONNECT_DEVICE_SUCCESS';

