import * as contant_types from '../contants/channel';

export const fetchChannelByDevie = (requets = {}, token) => ({
    type: contant_types.CHANNEL_FETCH_LIST_BY_DEVICE,
    payload: {
        requets,
        token
    }
})

export const fetchChannelByDevieSuccess = (data) => ({
    type: contant_types.CHANNEL_FETCH_LIST_BY_DEVICE_SUCCESS,
    payload: {
        data
    }
})