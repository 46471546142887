import { fork } from 'redux-saga/effects';
import handleAuthen from './auth';
import handleDevice from './device';
import handleChannel from './channel';
import handleGroup from './group';
import handleReport from './report';
import handleConfigValue from './configvalue';
import handleHistory from './history';


function* rootSaga() {
    try {
        yield fork(handleAuthen);
        yield fork(handleDevice);
        yield fork(handleChannel);
        yield fork(handleGroup);
        yield fork(handleReport);
        yield fork(handleConfigValue);
        yield fork(handleHistory);



    } catch (error) {
        console.log('error server!!!!!')
    }
}

export default rootSaga; 