import { jwtDecode } from "jwt-decode";

import * as authenTypes from "../contants/auth";
import { toastError, toastSuccess } from "../helpers/toastHelpers";
let user = JSON.parse(localStorage.getItem("user"));
const config = {
  showMessage: false,
  message: "",
  data_reset_pass: null,
  statusSignup: false,
  statusResend: null,
};
const initialState = user
  ? {
      isUserLoggedIn: true,
      accessToken: user.token,
      refreshToken: user.refreshToken,
      user: user.user,
      ...config,
    }
  : {
      isUserLoggedIn: false,
      accessToken: null,
      isSendMail: false,
      sendMailMessage: "",
      isSendMailSuccess: false,
      ...config,
    };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authenTypes.LOGIN_SUCCESS: {
      const { data } = action.payload;
      const { refreshToken, status, token, user } = data;
      if (status) {
        localStorage.setItem("user", JSON.stringify(data));
        return {
          isUserLoggedIn: true,
          accessToken: token,
          refreshToken: refreshToken,
          user,
        };
      } else {
        const { message } = data;
        return { ...state, showMessage: true, message };
      }
    }
    case authenTypes.RESET_PASS_SUCCESS: {
      const { data } = action.payload;
      const { refreshToken, status, token, user } = data;
      if (status) {
        localStorage.setItem("user", JSON.stringify(data));
        return {
          isUserLoggedIn: true,
          accessToken: token,
          refreshToken: refreshToken,
          user,
          data_reset_pass: data,
        };
      } else {
        return { ...state, data_reset_pass: data };
      }
    }

    case authenTypes.LOGIN_FALSE: {
      return { ...state, accessToken: null };
    }
    case authenTypes.LOGOUT: {
      localStorage.removeItem("user");
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        isUserLoggedIn: false,
        user: null,
      };
    }
    case authenTypes.SEND_MAIL_RESET_PASS_SUCCESS: {
      const { data } = action.payload;
      const { status, message } = data;
      return {
        ...state,
        isSendMail: true,
        isSendMailSuccess: status,
        sendMailMessage: message,
      };
    }
    case authenTypes.CHECK_TOKEN_LOGIN: {
      const { accessToken } = state;
      if (accessToken == null) {
        localStorage.removeItem("user");
        return { userLogin: null, isUserLoggedIn: false, accessToken: null };
      }
      if (user) {
        const { exp } = jwtDecode(accessToken);
        if (Date.now() >= exp * 1000) {
          localStorage.removeItem("user");
          return { userLogin: null, isUserLoggedIn: false, accessToken: null };
        }
      }
      return { ...state };
    }
    case authenTypes.SIGNUP_REQUEST_SUCCESS: {
      const { data } = action.payload;
      const { status } = data;
      if (status) {
        return { ...state, statusSignup: true };
      } else {
        toastError(data.message);
        return { ...state };
      }
    }
    case authenTypes.SIGNUP_REQUEST_CONFIRM_TOKEN_SUCCESS: {
      const { data } = action.payload;
      console.log(data);
      const { refreshToken, status, token, user } = data;
      if (status) {
        localStorage.setItem("user", JSON.stringify(data));
        return {
          isUserLoggedIn: true,
          accessToken: token,
          refreshToken: refreshToken,
          user,
        };
      } else {
        toastError(data.message);
        return { ...state };
      }
    }
    case authenTypes.SIGNUP_REQUEST_RESEND_TOKEN: {
      return { ...state, statusResend: null };
    }
    case authenTypes.SIGNUP_REQUEST_RESEND_TOKEN_SUCCESS: {
      const { data } = action.payload;
      const { status } = data;
      if (status) {
        toastSuccess("Thông tin xác nhận đã được gửi lại");
        return { ...state, statusResend: true };
      } else {
        toastError(data.message);
        return { ...state, statusResend: false };
      }
    }
    default:
      return state;
  }
};

export default reducer;
