import React from 'react';

//const SignUp1 = React.lazy(() => import('./Demo/Authentication/SignUp/SignUp1'));
//const Signin1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));
const Signin = React.lazy(() => import('./containers/auth/signin'));
const Reset = React.lazy(() => import('./containers/auth/reset'));
const SendMailReset = React.lazy(() => import('./containers/auth/send-mail'));
const Signup = React.lazy(() => import('./containers/auth/signup'));
const ConfirmMail = React.lazy(() => import('./containers/auth/confirm-mail'));


const route = [
    //{ path: '/auth/signup-1', exact: true, name: 'Signup 1', component: SignUp1 },
    //{ path: '/auth/signin-1', exact: true, name: 'Signin1', component: Signin1 },
    { path: '/auth/signin', exact: true, name: 'Signin', component: Signin },
    { path: '/auth/new-pass/:token', exact: true, name: 'Reset', component: Reset },
    { path: '/auth/send-mail-reset', exact: true, name: 'SendMailReset', component: SendMailReset },
    { path: '/auth/signup', exact: true, name: 'Signup', component: Signup },
    { path: '/auth/confirm-mail/:mail', exact: true, name: 'ConfirmMail', component: ConfirmMail },
    { path: '/auth/confirm-mail-success/:token', exact: true, name: 'ConfirmMail', component: ConfirmMail },



];
export default route;