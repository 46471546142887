import * as authenTypes from '../contants/auth';

export const handleLogin = ({ email, password }) => ({
    type: authenTypes.LOGIN_REQUEST,
    payload: {
        email,
        password
    }
})
export const handleSignup = (request) => ({
    type: authenTypes.SIGNUP_REQUEST,
    payload: {
        request
    }
})
export const handleSignupSuccess = data => {
    return {
        type: authenTypes.SIGNUP_REQUEST_SUCCESS,
        payload: {
            data
        }
    }
}
export const handleSignupConfirmToken = (token) => ({
    type: authenTypes.SIGNUP_REQUEST_CONFIRM_TOKEN,
    payload: {
        token
    }
})
export const handleSignupConfirmTokenSuccess = data => {
    return {
        type: authenTypes.SIGNUP_REQUEST_CONFIRM_TOKEN_SUCCESS,
        payload: {
            data
        }
    }
}

export const handleLoginSuccess = data => {
    return {
        type: authenTypes.LOGIN_SUCCESS,
        payload: {
            data
        }
    }
}
export const handleLoginFailed = error => {
    return {
        type: authenTypes.LOGIN_FALSE,
        payload: {
            error
        }
    }
}
export const handleReSendToken = (request) => ({
    type: authenTypes.SIGNUP_REQUEST_RESEND_TOKEN,
    payload: {
        request
    }
})
export const handleReSendTokenSuccess = data => {
    return {
        type: authenTypes.SIGNUP_REQUEST_RESEND_TOKEN_SUCCESS,
        payload: {
            data
        }
    }
}
export const handleSendMailReset = (request) => ({
    type: authenTypes.SEND_MAIL_RESET_PASS,
    payload: {
        request
    }
})

export const handleSendMailResetSuccess = data => {
    return {
        type: authenTypes.SEND_MAIL_RESET_PASS_SUCCESS,
        payload: {
            data
        }
    }
}
export const handleResetPass = ( token, password ) => ({
    type: authenTypes.RESET_PASS,
    payload: {
        token, password
    }
})

export const handleResetPassSuccess = data => {
    return {
        type: authenTypes.RESET_PASS_SUCCESS,
        payload: {
            data
        }
    }
}
export const handleLogout = () => {
    return {
        type: authenTypes.LOGOUT
    }
}

export const checkTokenLogin = () => ({
    type: authenTypes.CHECK_TOKEN_LOGIN
})
