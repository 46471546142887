import * as contant_types from '../contants/configvalue';

export const fetchConfigByDevie = (requets = {}, token) => ({
    type: contant_types.CONFIG_VALUE_FETCH_LIST_BY_DEVICE,
    payload: {
        requets,
        token
    }
})

export const fetchConfigByDevieSuccess = (data) => ({
    type: contant_types.CONFIG_VALUE_FETCH_LIST_BY_DEVICE_SUCCESS,
    payload: {
        data
    }
})