import { call, put, delay, takeEvery } from "redux-saga/effects";
import { STATUS_CODE } from "../contants";
import * as conatant_type from "../contants/device";

import {
  handleFetchListSuccess,
  handleCreateUpdateSuccess,
  handleDeleteSuccess,
  handleFetchItemByIdSuccess,
  handleUpdateSuccess,
  handlePaymentSuccess,
  handleFetchListRunSuccess,
  handleFetchGroupTimeSuccess,
  handleFetchErrorSuccess,
  handleFetchErrorByChannelSuccess,
  fetchListDeviceSuccess,
  handleConnectDeviceSuccess,
  handleRamoveConnectDeviceSuccess,
} from "../actions/device";

import {
  fetchLists,
  fetchListsPost,
  handleDeleteApi,
  handleUpdateApi,
  handleUpdateUploadApi,
  handleUploadFileApi,
} from "../apis/common";
let url_router_default = "device/";

function* handleFetchListSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(fetchListsPost, url_router_default, requets, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchListSuccess(data));
  }
}
function* handleFetchItemByIdSaga({ payload }) {
  const { id, token } = payload;
  const resp = yield call(fetchLists, url_router_default + id, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchItemByIdSuccess(data));
  }
}

function* handleCreateUpdateSaga({ payload }) {
  const { form_data, token } = payload;
  const resp = yield call(
    handleUploadFileApi,
    url_router_default + "create",
    form_data,
    token
  );

  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleCreateUpdateSuccess(data));
  }
}
function* handleDeleteSaga({ payload }) {
  const { id, token } = payload;
  const resp = yield call(handleDeleteApi, url_router_default, id, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleDeleteSuccess(data));
  }
}

function* handleUpdateItemSaga({ payload }) {
  const { form_data, token } = payload;
  const { _id } = form_data;
  let url = url_router_default + _id;
  const resp = yield call(handleUpdateUploadApi, url, form_data, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleUpdateSuccess(data));
  }
}
function* handlePaymentSaga({ payload }) {
  const { id, token } = payload;
  let url = url_router_default + "payment/" + id;
  const resp = yield call(handleUpdateApi, url, {}, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handlePaymentSuccess(data));
  }
}

function* handleFetchListRunSaga({ payload }) {
  const { requets, token } = payload;
  const { id } = requets;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "/history-run/" + id,
    requets,
    token
  );

  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchListRunSuccess(data));
  }
}
function* handleFetchGroupTimeSaga({ payload }) {
  const { requets, token } = payload;
  const { id } = requets;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "/group-by-date/" + id,
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchGroupTimeSuccess(data));
  }
}
function* handleFetchErrorSaga({ payload }) {
  const { requets, token } = payload;
  const { id } = requets;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "/report-error/" + id,
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchErrorSuccess(data));
  }
}
function* handleFetchErrorByChannelSaga({ payload }) {
  const { requets, token } = payload;
  const { id } = requets;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "/report-error-by-channel/" + id,
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchErrorByChannelSuccess(data));
  }
}
function* handleFetchListDeviceSaga({ payload }) {
  const { request, token } = payload;
  try {
    const resp = yield call(
      fetchListsPost,
      "user-device/find-device-by-user/",
      request,
      token
    );
    const { data, status } = resp;
    if (status === STATUS_CODE.SUSSESS) {
      yield put(fetchListDeviceSuccess(data, payload));
    }
  } catch (error) {
    console.log("error", error);
  }
}
function* handleConnectDeviceSaga({ payload }) {
  const { request, token } = payload;
  try {
    const resp = yield call(
      fetchListsPost,
      "device/connect-device/",
      request,
      token
    );
    const { data, status } = resp;
    if (status === STATUS_CODE.SUSSESS) {
      yield put(handleConnectDeviceSuccess(data, payload));
    }
  } catch (error) {
    console.log("error", error);
  }
}
function* handleRamoveConnectDeviceSaga({ payload }) {
  const { request, token } = payload;
  try {
    const resp = yield call(
      fetchListsPost,
      "user-device/delete-device-user/",
      request,
      token
    );
    const { data, status } = resp;
    if (status === STATUS_CODE.SUSSESS) {
      yield put(handleRamoveConnectDeviceSuccess(data, payload));
    }
  } catch (error) {
    console.log("error", error);
  }
}
function* handleDevice() {
  yield takeEvery(conatant_type.DEVICE_FETCH_LIST, handleFetchListSaga);
  yield takeEvery(
    conatant_type.DEVICE_FETCH_ITEM_BY_ID,
    handleFetchItemByIdSaga
  );

  yield takeEvery(conatant_type.DEVICE_CREATE_UPDATE, handleCreateUpdateSaga);
  yield takeEvery(conatant_type.DEVICE_DELETE, handleDeleteSaga);
  yield takeEvery(conatant_type.DEVICE_UPDATE, handleUpdateItemSaga);
  yield takeEvery(conatant_type.DEVICE_PAYMENT, handlePaymentSaga);
  yield takeEvery(conatant_type.DEVICE_FETCH_LIST_RUN, handleFetchListRunSaga);
  yield takeEvery(
    conatant_type.DEVICE_FETCH_GROUP_BY_TIMESTAMP,
    handleFetchGroupTimeSaga
  );
  yield takeEvery(conatant_type.DEVICE_FETCH_LIST_ERROR, handleFetchErrorSaga);
  yield takeEvery(
    conatant_type.DEVICE_FETCH_LIST_ERROR_BY_CHANNEL,
    handleFetchErrorByChannelSaga
  );
  yield takeEvery(conatant_type.FETCH_LIST_DEVICE, handleFetchListDeviceSaga);
  yield takeEvery(conatant_type.USER_CONNECT_DEVICE, handleConnectDeviceSaga);
  yield takeEvery(
    conatant_type.USER_REMOVE_CONNECT_DEVICE,
    handleRamoveConnectDeviceSaga
  );
}

export default handleDevice;
