import { call, put, delay, takeEvery } from "redux-saga/effects";
import { STATUS_CODE } from "../contants";
import * as conatant_type from "../contants/channel";

import { fetchChannelByDevieSuccess } from "../actions/channel";
import { fetchListsPost } from "../apis/common";

let url_router_default = "channel/";

function* handleFecthChannelSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "channels-by-device/",
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(fetchChannelByDevieSuccess(data));
  }
}

function* handleDevice() {
  yield takeEvery(
    conatant_type.CHANNEL_FETCH_LIST_BY_DEVICE,
    handleFecthChannelSaga
  );
}

export default handleDevice;
