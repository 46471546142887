import { call, put, delay, takeEvery } from 'redux-saga/effects';
import { STATUS_CODE } from '../contants';

import * as authenTypes from '../contants/auth';

import {
   handleLoginSuccess,
   handleLoginFailed,
   handleSendMailResetSuccess,
   handleResetPassSuccess,
   handleSignupSuccess,
   handleSignupConfirmTokenSuccess,
   handleReSendTokenSuccess
} from '../actions/auth';

import { handleLoginAPI } from '../apis/auth';
import { apiPost } from '../apis/common';
function* handleLoginSaga({ payload }) {
   const { email, password } = payload;
   const resp = yield call(handleLoginAPI, { email, password });
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleLoginSuccess(data));
   } else {
      yield put(handleLoginFailed(data))
   }
   yield delay(500);
}

function* handleSendMailSaga({ payload }) {
   const { request } = payload;
   const resp = yield call(apiPost, 'auth/send-mail-reset', request);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleSendMailResetSuccess(data));
   }
}
function* handleResetPassSaga({ payload }) {
   const resp = yield call(apiPost, 'auth/reset-pass', payload);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleResetPassSuccess(data));
   }
}

function* handleSignupSaga({ payload }) {
   const { request } = payload;
   const resp = yield call(apiPost, 'auth/register', request);
   const { data, status } = resp;
   console.log('data', data)
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleSignupSuccess(data));
   } 
   yield delay(500);
}

function* handleSignupConfirmTokenSaga({ payload }) {
   const { token } = payload;
   const resp = yield call(apiPost, 'auth/confirm-mail', token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleSignupConfirmTokenSuccess(data));
   } 
   yield delay(500);
}
function* handleSignupReSendTokenSaga({ payload }) {
   const { request } = payload;
   const resp = yield call(apiPost, 'auth/resend-token', request);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleReSendTokenSuccess(data));
   } 
   yield delay(500);
}
function* handleAuthen() {
   yield takeEvery(authenTypes.LOGIN_REQUEST, handleLoginSaga);
   yield takeEvery(authenTypes.SEND_MAIL_RESET_PASS, handleSendMailSaga);
   yield takeEvery(authenTypes.RESET_PASS, handleResetPassSaga);

   yield takeEvery(authenTypes.SIGNUP_REQUEST, handleSignupSaga);
   yield takeEvery(authenTypes.SIGNUP_REQUEST_RESEND_TOKEN, handleSignupReSendTokenSaga);

   yield takeEvery(authenTypes.SIGNUP_REQUEST_CONFIRM_TOKEN, handleSignupConfirmTokenSaga);



}

export default handleAuthen;