import * as conatant_types from "../contants/configvalue";
const initialState = {
  items: null,
  device: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case conatant_types.CONFIG_VALUE_FETCH_LIST_BY_DEVICE_SUCCESS: {
      const { data } = action.payload;
      console.log('data', data)
      if (data.status) {
        return {
          ...state,
          items: data.items,
          device: data.device,
        };
      }

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default reducer;
