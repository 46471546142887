import { call, takeEvery } from 'redux-saga/effects';
import * as conatant_type from '../contants/history';

import { fetchListsPost } from '../apis/common';
function* handleFetchListSaga({ payload }) {
   const { requets, token } = payload;
   const resp = yield call(fetchListsPost, 'history/create-item', requets, token);
}
function* handleAuthen() {
   yield takeEvery(conatant_type.CREATE_HISTORY, handleFetchListSaga);
}

export default handleAuthen;