import * as conatant_types from "../contants/channel";
import { toastError, toastSuccess } from "../helpers/toastHelpers";
const initialState = {
  channels: null,
  device: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case conatant_types.CHANNEL_FETCH_LIST_BY_DEVICE_SUCCESS: {
      const { data } = action.payload;
      if (data.status) {
        return {
          ...state,
          channels: data.channels,
          device: data.device,
        };
      }

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default reducer;
