import { combineReducers } from "redux";

import { reducer as formReducer } from "redux-form";
import ui from "./ui";
import auth from "./auth";
import device from "./device";
import channel from "./channel";
import group from "./group";
import report from "./report";
import configvalue from "./configvalue";




const rootReducer = combineReducers({
  ui,
  form: formReducer,
  auth,
  device,
  channel, group, report,configvalue
});

export default rootReducer;
